var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"login-form user-layout-login",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":_vm.isLoginErrorMessage}}):_vm._e(),_c('a-form-item',{staticClass:"mb-2"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {rules: [{ required: true, message: _vm.$t('header.login.empty') },{max: 50,message: _vm.$t('header.login.maximum',{msg: 50})}]},
            ]),expression:"[\n              'name',\n              {rules: [{ required: true, message: $t('header.login.empty') },{max: 50,message: $t('header.login.maximum',{msg: 50})}]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.name')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"mb-2"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'emailOrMobile',
              {rules: [{ required: true, message: _vm.$t('header.login.empty') },{max: 50,message: _vm.$t('header.login.maximum',{msg: 50})}]},
            ]),expression:"[\n              'emailOrMobile',\n              {rules: [{ required: true, message: $t('header.login.empty') },{max: 50,message: $t('header.login.maximum',{msg: 50})}]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.emailOrPhone')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),(_vm.verify)?_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticClass:"gutter-row",attrs:{"span":16}},[_c('a-form-item',{staticClass:"mb-2"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['otp', {rules: [{ required: true, message: 'Enter the verification code!' }], validateTrigger: 'blur'}]),expression:"['otp', {rules: [{ required: true, message: 'Enter the verification code!' }], validateTrigger: 'blur'}]"}],attrs:{"type":"text","placeholder":"Verification code"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1),_c('a-col',{staticClass:"gutter-row",attrs:{"span":8}},[_c('a-button',{staticClass:"getCaptcha",attrs:{"tabindex":"-1","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(!_vm.state.smsSendBtn && 'Get code' || (_vm.state.time+' s'))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getOTP.apply(null, arguments)}}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('a-form-item',{staticClass:"mb-2"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {rules: [{ required: true, message: _vm.$t('header.login.passrequired') },{max: 20,message: _vm.$t('header.login.maximum',{msg: 20})},
              {min: 6,message: 'Minimum length was 6 character'},{validator: _vm.validateToNextPassword},]},
            ]),expression:"[\n              'password',\n              {rules: [{ required: true, message: $t('header.login.passrequired') },{max: 20,message: $t('header.login.maximum',{msg: 20})},\n              {min: 6,message: 'Minimum length was 6 character'},{validator: validateToNextPassword},]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.password')}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('a-form-item',{staticClass:"mb-2"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm',
              {rules: [{ required: true, message: 'Please confirm your password!' },{validator: _vm.compareToFirstPassword}]},
            ]),expression:"[\n              'confirm',\n              {rules: [{ required: true, message: 'Please confirm your password!' },{validator: compareToFirstPassword}]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.conPassword')},on:{"blur":_vm.handleConfirmBlur}})],1)],1)],1),_c('div',{staticClass:"w-100"},[_c('a-button',{staticClass:"reg-btn",attrs:{"type":"primary","html-type":"submit","size":"large","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v(" CREATE ACCOUNT ")])],1),_c('div',{staticClass:"form-row justify-content-center mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"font-size":"12px"}},[_vm._v("By creating an account, you agree to the "),_c('br'),_vm._v(" "+_vm._s(_vm.$store.getters.generalSettings.app_name)+" "),_c('router-link',{attrs:{"to":"/terms-conditions"}},[_vm._v("Terms & Condition")]),_vm._v(" and "),_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v("Privacy Policy")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }