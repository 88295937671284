var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"login-form user-layout-login",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":_vm.isLoginErrorMessage}}):_vm._e(),_c('a-form-item',{staticClass:"mb-2"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'emailOrMobile',
              {rules: [{ required: true, message: _vm.$t('header.login.empty') },{max: 50,message: _vm.$t('header.login.maximum',{msg: 50})}]},
            ]),expression:"[\n              'emailOrMobile',\n              {rules: [{ required: true, message: $t('header.login.empty') },{max: 50,message: $t('header.login.maximum',{msg: 50})}]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.emailOrPhone')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"mb-2"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {rules: [{ required: true, message: _vm.$t('header.login.passrequired') },{max: 20,message: _vm.$t('header.login.maximum',{msg: 20})},
              {min: 6,message: _vm.$t('header.login.minimum',{msg: 6})},]},
            ]),expression:"[\n              'password',\n              {rules: [{ required: true, message: $t('header.login.passrequired') },{max: 20,message: $t('header.login.maximum',{msg: 20})},\n              {min: 6,message: $t('header.login.minimum',{msg: 6})},]},\n            ]"}],attrs:{"placeholder":_vm.$t('header.login.password')}})],1),_c('div',{staticClass:"w-100"},[_c('a-button',{staticClass:"reg-btn",attrs:{"type":"primary","html-type":"submit","size":"large","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v(" "+_vm._s(_vm.$t('header.login.signIn'))+" ")])],1),_c('div',{staticClass:"mt-1"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t('header.login.forgotPassword'))+"?")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }